@import './variables.scss';

@keyframes appear{
    from{ opacity: 0; } to{ opacity: 1; background-color: rgba(0,0,0,.7); }
}
@keyframes disappear{
    from { opacity: 1; } to{ opacity: 0; background-color: transparent; }
}
@keyframes moveIn{
    from{ bottom: -100%; opacity: 0; } to{ bottom: 0; opacity: 1; background-color: rgba(0,0,0,.8); }
}
@keyframes moveOut{
    from{ bottom: 0; opacity: 1; } to{ bottom: -100%; opacity: 0; background-color: transparent; }
}

.modal-container{
    animation-duration: .5s;

    position: fixed; top: 0; 
    left: 50%; transform: translate( -50%, 0 );
    // min-width: $iPhone8width;
    // width: 100vh / $iPhoneXmaxHeight * $iPhoneXmaxWidth; 
    width: 100vw;
    z-index: 9999;
    height: 100%;

    >.panel-wrap{
        animation-duration: 1s;

        z-index: 99999;
        position: fixed; left: 50%; transform: translate( -50%, 0 );
        bottom: 0; 
        width: 100%; height: auto;
        max-width: $iPhoneXmaxWidth;

        border-radius: 10px 10px 0 0;

        >.text-area-wrap{
            margin: 20px;
            text-align: center;

            >p:first-child{
                font-size: 24px;
                color: #F5A623;
                text-align: center;

                >span{
                    margin-right: 4px;
                    font-style: italic;
                }
            }
            >p:last-child{
                margin-top: 20px;
                text-align: center;
                font-size: 14px; line-height: 20px;
                color: #aaa;
            }
        }

        >.buttons-wrap{
            margin: 20px;
            >div{ // action-button-wrap, default-button-wrap
                >div { // action-button, .default-button
                    margin-top: 10px;
                    width: 100%; height: 50px;
                    border-radius: 25px;
                    cursor: pointer;
    
                    >p{ // action-text
                        display: inline-block   ;
                        margin: 14px;
                        font-size: 16px;
                        color: #fff;
                    }
                    >.action-icon{
                        margin-top: 4px;
                        display: inline-block;
                        width: 40px; height: 40px; border-radius: 20px;
                        background-color: rgba( $yellowOrange, .8 );
                        vertical-align: middle;
                    }
                }
                >p{ // description
                    margin: 4px auto;
                    max-width: 80%;
                    text-align: center;
                    font-size: 14px;
                    line-height: 16px;
                    color: rgba(255,255,255,.8);
                }

                >.action-button{
                    background-color: #111;
                }
    
                >.default-button{
                    background-color: #222;
                }
            }
        }
    }


}