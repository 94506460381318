
$iPhoneXmaxWidth: 1242px /2;
$iPhoneXmaxHeight: 2688px /2;

$iPhoneXwidth: 1125px /2;
$iPhoneXheight: 2436px /2;

$iPhone8width: 750px /2;
$iPhone8height: 1334px /2;

$iPhone8plusWidth: 1080px /2;
$iPhone8plusHeight: 1920px /2;

$tabBarHeight: 60px;


$titleTextColor: #000;
$bodyTextColor: #4A4A4A;


$primaryColor: #735236;
$yellowOrange: #F5A623;
$white: #fff;
$navyBlue: #00467B;
$gray: #4A4A4A;
$lightGray: #888;
$tabBarBackgroundColor: rgba(0,0,0,.8);
$bodyBackgroundColor: #1caae0;