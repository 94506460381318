@import './variables.scss';

.paragraph-container{
    transition: all 1s;
    
    position: relative; 
    padding: 0;
    text-align: left;
    background-color: #fff;

    >.top-triangle-screen{
        position: absolute; top: 0; left: 0;
        width: 100%; height: 50px;
    }
    >.bottom-triangle-screen{
        position: absolute; bottom: 0; right: 0;
        width: 100%; height: 50px;
    }

    >.title-wrap{
        width: 100%;
        text-align: center;
        padding-bottom: 20px;
        >p{
            font-size: 24px; line-height: 32px; font-weight: 600;
            color: #5a5a5a;
        }
    }

    >.carousel-wrap{
        width: 100%; text-align: center;
        // min-height: 50px;
        padding-bottom: 60px;

        .carousel-slider{
            margin: 0 !important;
        }
        img { border-radius: 0; }
        .control-dots{
            margin: 0; padding: 0;
            >.dot{ margin: 0 2px; }
        }
    }
    >.description-wrap{
        padding: 0 20px 20px;
        >p{
            font-size: 14px; line-height: 24px; font-weight: 300;
            color: $gray;
        }
    }
}