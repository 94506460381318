@import './variables.scss';

.big-header-container{
    transition: all 1s;
    
    position: relative;
    width: 100%; height: 310px;
    background: linear-gradient(162.76deg, #60B0EE 0%, #92C7E4 27.88%, #B4E2E9 100%);
    
    >.bottom-triangle-screen{
        position: absolute; bottom: 0; right: 0;
        width: 100%; height: 50px;
    }
    >.navigation-wrap{
        width: 100%; height: 60px;
        position: relative;
        display: block;

        >.left-wrap{
            display: inline-block;
            position: absolute; top: 20px; left: 20px;

            >.button{
                color: #fff; font-size: 14px; text-decoration: none;

            }
            
        }
        >.right-wrap{
            display: inline-block;
            position: absolute; top: 10px; right: 20px;
            width: 40px; height: 40px; border-radius: 20px;
            background-color: rgba(255, 255, 255, .4);
            
        }
    }
    
    >.title-wrap{
        >.title-text{
            margin-top: 4px;
        }
        >.subtitle-text{
            font-size: 12px; color: $white; 
        }
    }

    >.button-wrap{
        width: 100%;
        padding: 13px 0;

        >.book-button{
            margin-left: auto; margin-right: auto;
            width: 136px;
            padding: 10px 20px;
            border-radius: 18px;
            background-color: rgba( #089A5Cdd, .8 );

            text-align: center;
            font-size: 14px; color: $white; text-decoration: none;
        }
    }


}