@import './variables.scss';

@keyframes bluegreensolid{
    0% { background-color: #3899d2; }
    50% { background-color: #86bdcc; }
    100% { background-color: #3899d2; }
}
*{ transition: all 1s; }
html, body, p{ margin: 0;}
html, body, #root { overflow: hidden; }
#root {
    width: 100vw; height: 100vh;

    animation-name: bluegreensolid;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    // background: linear-gradient( 173deg, #3899d2 0%, #86bdcc 100% );
}

.App{
    position: relative;
    margin: auto;
    background-color: #000;
    overflow: hidden;

    min-height: $iPhone8height;
    min-width: $iPhone8width;
    max-width: $iPhoneXmaxWidth;
    // max-width: 414px;
    max-height: $iPhoneXmaxHeight;

    //the following changes in mobile environment
    height: 100vh;
    // width: 100vh / $iPhoneXmaxHeight * $iPhoneXmaxWidth;
    
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, .2);
}
@media ( max-width: $iPhoneXmaxWidth ) { 
    .App { 
        width: 100vw; height: 100vh;

        .tab-bar-container{
            width: calc(100vw - 8px);
        }
        .modal-container{
            width: 100vw; left: 0; transform: none;
        }
    } 
}

.tab-bar-container{
    position: fixed; bottom: 4px; left: 50%; transform: translate( -50%, 0 );
    margin: auto;
    min-width: 360px;
    width: calc( (100vh / (2688 * 1242)) - 8px ); 
    height: $tabBarHeight;
    background-color: rgba(255, 255, 255, .8);
    border-radius: 40px;

    >.contents{
        position: relative;
        width: 100%; height: 60px;
        padding: 0;
        text-align: center;

        >.button{
            position: relative; margin: 5px;
            display: inline-block; 
            width: 50px; height: 50px; border-radius: 30px;
            cursor: pointer; 
            >.button-icon{
                position: absolute; top: 45%; left: 50%; transform: translate(-50%, -50%);
                width: 24px; height: 24px;
            }
            >p{
                position: absolute; bottom: 4px; left: 50%; transform: translateX( -50% );
                font-size: .4rem; color: #000; 
            }
        }
    }
}

.screen-container{
    width: 100%; height: 100%;
    padding-bottom: 50px;
    overflow: scroll;
}

.rooms-container{
    >.button-wrap{
        width: 100%;
        padding: 0 0 20px;
        background-color: #fff;

        >.book-button{
            margin-left: auto; margin-right: auto;
            width: 136px;
            padding: 10px 20px;
            border-radius: 18px;
            background-color: rgba( #089A5Cdd, .8 );

            text-align: center;
            font-size: 14px; color: $white; text-decoration: none;
        }
    }
}

