@import './variables.scss';

.header-container{
    transition: all 1s;
    
    position: relative;
    width: 100%; height: 110px; text-align: left;
    background: linear-gradient(162.76deg, #60B0EE 0%, #92C7E4 27.88%, #B4E2E9 100%);
    
    >.title-text{
        vertical-align: top; margin-top: 28px;
        display: inline-block;
    }

    >.bottom-triangle-screen{
        position: absolute; bottom: 0; right: 0;
        width: 100%; height: 50px;
    }
}